<template>
  <div>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      v-model="selected"
      :key="selected.length"
      show-select
      item-key="_id"
      hide-default-footer
      @input="$emit('on-selected', selected)"
    >
      <template v-slot:[`item.customer_name`]="{ item }">
        {{ item.ownership.customer_name ? item.ownership.customer_name : 'ไม่มีชื่อ เจ้าของรถ หรือ ถูกลบ' }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{ item.car_type == 'CT1' ? 'รย.1' : '' }}
        {{ item.car_type == 'CT2' ? 'รย.2' : '' }}
        {{ item.car_type == 'CT3' ? 'รย.3' : '' }}
        {{ item.car_type == 'CT4' ? 'รย.12' : '' }}
        {{ item.car_type == 'CT13' ? 'รย.17' : '' }}
        {{ item.car_type == 'CT5' ? 'รย.13' : '' }}
        {{ item.car_type == 'CT14' ? 'รย.15' : '' }}
        {{ item.car_type == 'CT6' ? 'ขส.10' : '' }}
        {{ item.car_type == 'CT7' ? 'ขส.30' : '' }}
        {{ item.car_type == 'CT8' ? 'ขส.40' : '' }}
        {{ item.car_type == 'CT9' ? 'ขส.70' : '' }}
        {{ item.car_type == 'CT10' ? 'ขส.80' : '' }}
        {{ item.car_type == 'CT11' ? 'รย.1EV' : '' }}
        {{ item.car_type == 'CT12' ? 'อื่น ๆ' : '' }}
      </template>
      <template v-slot:[`item.customer_phone_number`]="{ item }">
        {{ item.ownership.phone_number }}
      </template>
      <template v-slot:[`item.taxout_date`]="{ item }">
        {{ formatDate(item.taxout_date) }}
      </template>

      <template v-slot:[`item.3rd_insurance_enddate`]="{ item }">
        <span v-if="item[`3rd_insurance_enddate`] == ''"></span>
        <span v-if="item[`3rd_insurance_enddate`] != ''">
          {{ formatDate(item[`3rd_insurance_enddate`]) }}</span
        >
      </template>

      <template v-slot:[`item.insurance_enddate`]="{ item }">
        <span v-if="item.insurance_enddate == ''"></span>
        <span v-if="item.insurance_enddate != ''">
          {{ formatDate(item.insurance_enddate) }}</span
        >
      </template>

      <template v-slot:[`item.location`]="{ item }">
        <span v-if="item.ownership.delivery_address && item.ownership.location">
          <span v-if="item.ownership.delivery_address.address === ''">
            {{ item.ownership.location.address }}
            {{ item.ownership.location.sub_district }}
            {{ item.ownership.location.district }}
            {{ item.ownership.location.province }}
            {{ item.ownership.location.postal_code }}
          </span>
          <span v-else>
            {{ item.ownership.delivery_address.address }}
            {{ item.ownership.delivery_address.sub_district }}
            {{ item.ownership.delivery_address.district }}
            {{ item.ownership.delivery_address.province }}
            {{ item.ownership.delivery_address.postal_code }}
          </span>
        </span>
      </template>

      <!-- <template v-slot:[`item.sms_remind`]="{ item }">
        <v-btn
          v-if="item.sms_remind == false"
          color="primary"
          small
          outlined
          @click="updateStatus(item._id, 0)"
        >
          กดเพื่อส่ง
        </v-btn>
        <v-btn v-show="false" v-if="item.sms_remind" color="primary" small>
          ส่งแล้ว
        </v-btn>
      </template>
      <template v-slot:[`item.tel_alert`]="{ item }">
        <v-icon v-if="item.tel_alert">mdi-check</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.tell`]="{ item }">
        <div v-if="item.tel_alert">
          <v-btn
            v-if="item.tel_alert == '0'"
            color="primary"
            width="140px"
            small
            outlined
            @click="updateStatus(item._id, 1)"
            >รอโทรแจ้งลูกค้า</v-btn
          >
          <v-btn v-else color="primary" small @click="updateStatus(item._id, 1)"
            >โทรแล้ว {{ item.tell }} ครั้ง</v-btn
          >
        </div>
      </template> -->
      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from 'moment';
export default {
  props: [
    'carType',
    'updateList',
    'productType',
    'startDate',
    'endDate',
    'timeRangeType',
    'showOnlyAddress',
  ],
  data: () => ({
    dialogForm: false,
    dialogFormData: null,
    oldData: null,
    selected: [],
    loading: true,
    list: [],
    allList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 200,
    item: {
      process_array: [],
    },
    filter: {
      item_per_page: 200,
      branch_id: null,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(30, 'days').format('YYYY-MM-DD'),
      call_remind_renewal: true,
      car_type: 0,
      product_type: 0,
      page: 1,
      sms_remind: false,
    },
    headers: [
      {
        text: 'ทะเบียน',
        align: 'start',
        sortable: false,
        value: 'car_plate',
      },
      { text: 'จังหวัด', value: 'plate_province' },
      { text: 'ประเภทรถ', value: 'car_type' },
      { text: 'ชื่อ', value: 'customer_name' },
      { text: 'เบอร์โทร', value: 'customer_phone_number' },
      { text: 'ภาษีขาด', value: 'taxout_date' },
      { text: 'พรบ ขาด', value: '3rd_insurance_enddate' },
      { text: 'ประกันขาด', value: 'insurance_enddate' },
      { text: 'ที่อยู่', value: 'location' },
      //{ text: 'SMS', value: 'sms_remind', align: 'center' },
      // { text: 'สถานะ SMS', value: 'sms_status', align: 'center' },
      // { text: 'โทรแจ้งลูกค้า', value: 'tel_alert', align: 'center' },
      // { text: 'รอโทรแจ้งลูกค้า', value: 'tell', align: 'center' }
    ],
  }),
  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },
  methods: {
    async getData() {
      this.list = [];
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/get_document_remind_renewal`, body)
        .then((res) => {
          this.list = this.prepareProcessArray(res.result.data);
          this.allList = this.list
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async updateStatus(id, btnAction, value) {
      this.loading = true;
      // btnAction => 0 = sms, 1 = call
      await this.$axios
        .post(`${this.$baseUrl}/document/get_by_id`, {
          token: this.$jwt.sign({ document_id: id }, this.$privateKey, {
            noTimestamp: true,
          }),
        })
        .then(async (res) => {
          let oldData = res.result;
          if (btnAction == 0) {
            pay_load = {
              document_id: id,
              sms_remind: true,
              call_remind: oldData.call_remind,
            };
            await this.$axios
              .post(`${this.$baseUrl}/document/edit_remind`, {
                token: this.$jwt.sign(pay_load, this.$privateKey, {
                  noTimestamp: true,
                }),
              })
              .then((res) => {
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          } else if (btnAction == 1) {
            oldData.call_remind =
              oldData.call_remind < 3 ? oldData.call_remind + 1 : 0;
            // fill old data to edit payload
            pay_load = {
              document_id: id,
              sms_remind: oldData.sms_remind,
              call_remind: oldData.call_remind,
            };
            // update data
            await this.$axios
              .post(`${this.$baseUrl}/document/edit_remind`, {
                token: this.$jwt.sign(payload, this.$privateKey, {
                  noTimestamp: true,
                }),
              })
              .then((res) => {
                this.getData();
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    prepareProcessArray(process_array) {
      let list = [];
      if (
        process_array &&
        process_array.length &&
        typeof process_array === 'object'
      ) {
        for (var i = 0; i < process_array.length; i++) {
          var o = Object.assign({}, process_array[i]);
          o.tel_alert = 'call_alert' in o ? o.call_alert : false;
          o.tell = 'call_remind' in o ? o.call_remind : 0;
          list.push(o);
        }
      }
      return list;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
      this.resetCheckbox()
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    resetCheckbox() {
      this.$emit('reset-checkbox');
    },
  },
  watch: {
    carType(nv, ov) {
      if (nv != ov) {
        this.filter.car_type = nv;
        this.getData();
      }
    },
    productType(nv, ov) {
      if (nv != ov) {
        this.filter.product_type = nv;
        this.getData();
      }
    },
    startDate: function (nv, ov) {
      if (nv != ov) {
        this.filter.start_date = nv;
        if (this.timeRangeType == 3) {
          this.getData();
        }
      }
    },
    endDate: function (nv, ov) {
      if (nv != ov) {
        this.filter.end_date = nv;
        if (this.timeRangeType == 3) {
          this.getData();
        }
      }
    },
    timeRangeType: function (value) {
      if (value == 0) {
        // 0 = วันนี้ + 30วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+30, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 1) {
        // 1 = วันนี้ + 60วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+60, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 2) {
        // 2 = วันนี้ + 90วัน
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment()
          .add(+90, 'days')
          .format('YYYY-MM-DDTHH:mm:ss');
      } else if (value == 3) {
        // 3 = เลือกวันที่
        this.filter.start_date = moment().format('YYYY-MM-DDTHH:mm:ss');
        this.filter.end_date = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
      this.getData();
    },
    showOnlyAddress(newVal) {
      if (newVal === true) {
        this.list = this.list.filter((item) => {
          return (
            item.ownership.delivery_address.address !== '' ||
            item.ownership.location.address !== ''
          );
        });
      } else {
        this.list = this.allList
      }
    },
  },
};
</script>
